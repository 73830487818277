import { graphql } from 'gatsby'
import React, { FC } from 'react'
import Layout from '@/containers/Layout'
import JobContainer from '@/containers/JobContainer'
import { Job } from 'web/types/custom-graphql-types'

interface JobProps {
  data: {
    job: Job
  }
}

const AnyJob: FC<JobProps> = ({ data: { job }, ...props }) => (
  <Layout
    title={job.title}
    _type={'job'}
    seoSettings={job.seoSettings}
  >
    <JobContainer job={job} />
  </Layout>
)

export default AnyJob

export const query = graphql`
  query ($id: String!) {
    job: sanityJobs(id: { eq: $id }) {
      title
      seoSettings {
        ...seoSettingsData
      }
      contentModules {
        ...contentModulesJobsData
      }
    }
  }
`

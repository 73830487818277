import React, { FC } from 'react'
import { Job } from 'web/types/custom-graphql-types'
import ModulesLoop from '@/modules'

interface JobProps {
  job: Job
}
const JobContainer: FC<JobProps> = (props) => {
  const { contentModules } = props.job

  return <ModulesLoop modules={contentModules?.modules} templateType="job" />
}

export default JobContainer
